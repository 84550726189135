#OTPModal ion-input {
    border: 1px solid #f3f0fe;
    border-radius: 5px;
    margin: 10px auto;
    width: 250px;
    text-align: center;
    --padding-start: 20px;
    letter-spacing: 10px;
    font-weight: 600;
    color: var(--ion-color-primary);
    caret-color: transparent;
}