.table-card {
  -moz-transform: scale(0.8, 0.8);
  zoom: 0.8;
  zoom: 80%;
}



.Home__content .datatable {
  height: 600px;
  overflow-y: scroll;
  background-color: var(--ion-color-light)
}

.Home__content ion-card-content {
  padding: 0;
}

.Home__footer {
  background-color: whitesmoke
}

.Home__content .table-card .button-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 20px;
}

.Home__content .table-card ion-card-title {
  font-size: 16px;

}

.label-icon {
  width: 60px !important;
  height: 20px !important;
  padding: 5px;
  border: 2px solid var(--ion-color-medium);
  z-index: 999999;
  border-radius: 3px;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: 18px;
  z-index: -11;
  left: 0;
  border-top: 10px solid var(--ion-color-medium);
}


.icon-span {
  position: absolute;
  top: -1px;
  color: white;
}


.label-icon.green {
  background-color: var(--ion-color-success);
}

.label-icon.red {
  background-color: var(--ion-color-danger);
}

.label-icon.orange {
  background-color: var(--ion-color-warning);
}

table tbody tr>td:nth-child(4) div {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}

table tbody tr:hover>td:nth-child(4) div {
  white-space: wrap;
  overflow: visible;
  width: auto;
  text-overflow: inherit;
}