#Receipt {
    background-color: white;
    color: black !important;
    display: none;
    width: 100%;
}


#Receipt .Receipt__header {
    border-bottom: 1px dashed black;
    font-weight: bolder;
}


#Receipt .Receipt__header .logo {
    font-size: 24px;
}

#Receipt .Receipt__content {
    border-bottom: 1px dashed black;
    font-weight: 500;
}

#Receipt .Receipt__content__address {
    margin-bottom: 15px;
    border-bottom: 1px dashed black;
}


#Receipt .Receipt__content_payment-details {
    text-align: end;
}

#Receipt .Receipt__content__table {
    margin-bottom: 50px;
    width: 100%;
}

#Receipt .Receipt__content__table th, #Receipt .Receipt__content__table td {
    padding: 2px;
}

#Receipt .Receipt__footer {
    font-size: 18px;
    font-weight: 600;
    padding-top: 10px
}

@media print {
    body * {
        visibility: hidden;
    }

    #Receipt {
        display: block;
    }

    #Receipt,
    #Receipt * {
        visibility: visible;
    }

    #Receipt {
        position: absolute;
        left: 0;
        top: 0;
    }
}