#Checkbox{
    border: 1px solid #f3f0fe;
    border-radius: 5px;
    background-color: white;
}

#Checkbox ion-label {
    font-weight: 600;
    font-size: 14px;
    color: var(--ion-color-primary);
}
