div#SectionTitle {
    width: 100%; height: 12px; border-bottom: 1px solid #f3f0fe; text-align: center; 
}

#SectionTitle .title {
    font-size: 12px; 
    background-color: rgb(250,250,250); 
    padding: 0 10px;
    color: var(--ion-color-medium);
    font-weight: 500;
}
