 #WarehouseDetail #locate-button {
    position: absolute;
    z-index: 1;
    height: 40px;
    width: 40px;
    border-radius: 100px;
    font-size: 26px !important;
    box-shadow: 0 1px 2px 1px rgb(0 0 0 / 15%), 0 2px 5px -3px rgb(0 0 0 / 15%);
    background-color: white;
    text-align: center;
    padding-top: 8px;
    left: 10px;
    top: 10px;
}
