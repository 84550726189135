#ProductItem .product-card {
    margin: 5px;
    box-shadow: unset;
    border-radius: 15px;
    background-color: white;
    border: 1px solid #F9F8FC;
}

#ProductItem .product-price {
    margin: 8px 8px 0 8px;
    line-height: 1;
    font-size: 14px;
    font-weight: 700;
}

#ProductItem .product-price s {
    color: var(--ion-color-medium);
}

#ProductItem .product-price span {
    color: var(--ion-color-primary);
}

#ProductItem .product-title {
    margin: 5px 8px 8px 8px;
    font-size: 12px;
    color: var(--ion-color-dark);
    font-weight: 600;
    max-width: 160px;
}

#ProductItem .product-sub-title {
    margin: 8px;
    font-size: 12px;
    color: var(--ion-color-medium);
    line-height: 0;
    font-weight: 600;
}


#ProductItem ion-item {
    --padding-start: 0;
}

#ProductItem ion-item .product-title {
    /* margin-top: -1px; */
}


#ProductItem ion-item .product-sub-title {
    --padding-start: 0;
    line-height: unset;
    margin-top: -8px;
}

#ProductItem  ion-thumbnail{
    border: 1px solid #F9F8FC;
    --border-radius: 10px;
    --size: 50px 50px;
}

#ProductItem ion-item .product-price {
    margin-top: -5px;
}
