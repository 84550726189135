#Header ion-back-button {
    --color: var(--ion-color-medium);
    font-weight: 500;
    font-size: 14px;
    --icon-font-size: 20px;
    --icon-margin-bottom:2px;
}

#Header ion-toolbar {
    --background: rgb(77,58,159);
    --background: linear-gradient(270deg, rgba(77,58,159,1) 0%, rgba(93,62,188,1) 35%, rgba(105,85,188,1) 100%);
    color: #fff;
}
