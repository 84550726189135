#SettingTable {
    width: 100%;
}

#SettingTable img {
    width: 50px;
}

#SettingTable .limit-select {
    height: 30px;
}

#SettingTable td {
    vertical-align: middle;
}

#SettingTable .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.table-sm td, .table-sm th {
    padding: 0.3rem;
}
#SettingTable .interval-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--ion-color-success);
    display: inline-flex;
    position: relative;
    left: 5px;
}

.blink_me {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

#SettingTable ion-col {
    padding: 0
}

#SettingTable ion-input {
    border: 1px solid #dee2e6;
}

#SettingTable .action-button {
    font-size: 12px;
    text-transform: capitalize;
}

#SettingTable .table-bordered {
    border: 1px solid #dee2e6;
}

#SettingTable ion-button>ion-icon {
    font-size: 12px;
}
#SettingTable .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}

#SettingTable ion-searchbar {
    padding: 0;
}

#SettingTable td div.td-div, #SettingTable th div.td-div {
    height: 20px; 
    overflow:hidden;
    min-width: 150px;
}

.table-bordered thead th {
    border-bottom-width: 2px;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.table-bordered td,
.table-bordered th {
    border: 1px solid #dee2e6;
}

.table td,
.table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

tr.selected {
    background-color: #eee;
}

td,
th {
    padding: 0;
}

th {
    text-align: inherit;
}