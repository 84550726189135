ion-menu ion-content {
  --background: rgb(77,58,159);
  --background: linear-gradient(90deg, rgba(77,58,159,1) 0%, rgba(93,62,188,1) 35%, rgba(105,85,188,1) 100%);
}

ion-menu ion-list {
  background: transparent !important;
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-item {
  color: #fff;
}


ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header, ion-menu.md ion-note {
  padding-left: 10px;
}


ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
  
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-secondary);
}


ion-menu.md ion-item ion-icon {
  color: #fff;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-split-pane * {
  border: 0;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-note {
  display: inline-block;
  font-size: 12px;
  color: var(--ion-color-light);
}

ion-menu ion-item {
  --background: transparent;
}

ion-item.selected ion-label {
  color: var(--ion-color-secondary) !important
} 